import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Label from "../../components/Label/Label";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TextField from "../../components/TextField/TextField";
import HookForm from "../../components/HookForm/HookForm";
import SearchBox from "../../components/SearchBox/SearchBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CardImg from "../../assests/img/card.svg";
import IpadImg from "../../assests/img/ipad.svg";
import UploadImg from "../../assests/img/uploadimg.svg";
import PolicyImg from "../../assests/img/policy.png";
import { Dropdown, Form } from "react-bootstrap";
import "./DetailsPage.scss";
import Dropdownx from "../../components/Dropdown/Dropdownx";
import Header from "../../components/Header/Header";
import ModalPopup from "../../components/ModalPopup/ModalPopup";
import LeaveCard from "../../components/LeaveCard/LeaveCard";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsPageAction } from "../../reduxThings/slices/wbildSlices";
const loginForm = {
  title: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
  FAQ: {
    name: "email",
    validate: {
      required: {
        value: true,
      },
    },
  },
};

const listData = [
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
  {
    brief:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque",
    answer:
      "totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam",
  },
];
const holidayList = [
  {
    date: "01-01-2022",
    day: "Sunday",
    type: "New Year",
  },
];

const dropdownItem = [
  {
    id: "1",
    value: "New Year",
  },
  {
    id: "2",
    value: "Makar Sankranti",
  },
  {
    id: "3",
    value: "Vasant Panchmi",
  },
];

const LeaveType = [
  {
    id: "1",
    value: "Paid",
  },
  {
    id: "2",
    value: "Medical",
  },
  {
    id: "3",
    value: "Casual",
  },
  {
    id: "3",
    value: "Leave",
  },
];

const statusType = [
  {
    id: "1",
    value: "Approval",
  },
  {
    id: "2",
    value: "Rejected",
  },
  {
    id: "3",
    value: "Pending",
  },
];
const tableData = [
  {
    startdate: "8/30/14",
    enddate: "8/30/14",
    leavetype: "PL",
    reason: "Amet minim mollit non deserunt ullam",
    status: "Pending",
  },
  {
    startdate: "8/30/14",
    enddate: "8/30/14",
    leavetype: "PL",
    reason: "Amet minim mollit non deserunt",
    status: "Approved",
  },
  {
    startdate: "8/30/14",
    enddate: "8/30/14",
    leavetype: "PL",
    reason: "Amet minim mollit non deserunt ullam",
    status: "Approved",
  },
  {
    startdate: "8/30/14",
    enddate: "8/30/14",
    leavetype: "PL",
    reason: "AAmet minim mollit non deserunt",
    status: "Pending",
  },
];
const queryTable = [
  {
    Phone: "(316) 555-0116",
    Email: "felicia.reid@example.com",
    Date: "9/23/16",
  },
  {
    Phone: "(316) 555-0116",
    Email: "felicia.reid@example.com",
    Date: "9/23/16",
  },
  {
    Phone: "(316) 555-0116",
    Email: "felicia.reid@example.com",
    Date: "9/23/16",
  },
  {
    Phone: "(316) 555-0116",
    Email: "felicia.reid@example.com",
    Date: "9/23/16",
  },
];

function DetailsPage() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form, setLoginForm] = useState();
  const [tab, setTab] = useState("contactForm");
  const [addBlog, setAddBlog] = useState(false);
  const [accordion, setActiveAccordion] = useState(-1);
  const [hidemodal, setHidemodal] = useState(false);
  const [hideholidaymodal, setHideHolidaymodal] = useState(false);
  const [staff, setSelectedStaff] = useState();
  const [applyLeave, setApplyLeave] = useState(true);
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    dispatch(getDetailsPageAction());
  }, []);
  const appData = useSelector(
    (e) => e.wbildSlice?.DetailsPageState?.DetailsPageStateData
  );
  console.log("details page", appData);
  const document = [
    {
      id: 1,
      name: "Jen Tile",
      email: "jentile@gmail.com",
      contactNumber: "91256834",
      updateAt: "12/10/2022",
      service: "Website service",
      message: "Hello this side Jen Tile I want to make a website...",
    },
    {
      id: 1,
      name: "Lynne Gwistic",
      email: "lynnegwistic@gmail.com",
      contactNumber: "91256834",
      updateAt: "12/10/2022",
      service: "Website Work",
      message: "Hello this side Lynne Gwistic I want to make a website...",
    },
    {
      id: 1,
      name: "Bea Mine",
      email: "beamine@gmail.com",
      contactNumber: "91256834",
      updateAt: "12/10/2022",
      service: "Website Work",
      message: "Hello this side Bea Mine I want to make a website...",
    },
    {
      id: 1,
      name: "C. Yasoon",
      email: "cyasoon@gmail.com",
      contactNumber: "91256834",
      updateAt: "12/10/2022",
      service: "Node JS",
      message: "Hello this side C. Yasoon I want to make a website...",
    },
    {
      id: 1,
      name: "Ben Dover",
      email: "bendover@gmail.com",
      contactNumber: "91256834",
      updateAt: "12/10/2022",
      service: "Android dev",
      message: "Hello this side Ben Dover I want to make a website...",
    },
  ];
  function toggleAccordion(index) {
    setActiveAccordion(index);
  }
  const handleTab = (event) => {
    if (event === "contactForm") {
      setTab("contactForm");
    } else {
      setTab("query");
    }
  };
  const onClickApplyLeave = () => {
    setApplyLeave(!applyLeave);
  };

  const onFormSubmit = () => {};
  const handleOnBlog = () => {
    setAddBlog(!addBlog);
  };
  // const api =
  //   "https://9md76gcnig.execute-api.us-east-1.amazonaws.com/development/queries";
  // useEffect(() => {
  //   axios
  //     .get(api, {
  //       headers: {
  //         Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJjYzBkOTA3Yi1hMGExLTQ0YjUtYWRiZC04ZmNlODFkNWUzYzgiLCJyb2wiOlsiVVNFUiIsIkFETUlOIl0sImlhdCI6MTY1MzYyOTQ5NCwiZXhwIjoxNjU0MjM0Mjk0fQ.CxLa3UESgBJVaSouxfapFe6vI7bx1X7gMGJ8Oygyq4c"}`,
  //       },
  //     })
  //     .then((res) => {
  //       setCrtficte(res.data.items);
  //     });
  // }, []);
  // console.log(crtficte);
  return appData?.Tabs?.blog?.title === "Blog" ? (
    <div className="blog_Page">
      <Header handleClick={handleClick} />
      <div className="blog_Main">
        <Tabs
          defaultActiveKey="blog"
          id="uncontrolled-tab-example"
          className="tab_Main_Section"
        >
          <Tab eventKey="blog" title={appData?.Tabs?.blog?.title}>
            {addBlog && (
              <div className="upload_Section">
                <i className="icon-back backIcon" />
                <div className="img_Section">
                  <div className="left_Section width50">
                    <img src={UploadImg} className="blog_Img" alt="..." />
                    <div className="icon_Bg">
                      <i className="icon-delete deleteIcon" />
                    </div>
                  </div>
                  <Form.Group
                    controlId="formFileMultiple"
                    className="right_Section width50"
                  >
                    <Form.Control type="file" multiple />
                    <div className="add_Image cursorP">
                      <i className="icon-add addIcon" />
                      <Form.Label className="cursorP">
                        {appData?.Tabs?.blog?.AddBlog?.imgPlaceholder}
                      </Form.Label>
                    </div>
                  </Form.Group>
                </div>
                <div className="content_Section">
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setLoginForm(form)}
                    onSubmit={onFormSubmit}
                  >
                    {(formMethod) => {
                      return (
                        <div className="form1">
                          <TextField
                            formMethod={formMethod}
                            name={loginForm.title.name}
                            autoFocus={true}
                            type="text"
                            placeholder={appData?.Tabs?.blog?.AddBlog?.heading}
                          />
                          <TextField
                            formMethod={formMethod}
                            name={loginForm.FAQ.name}
                            autoFocus={true}
                            type="text"
                            placeholder={
                              appData?.Tabs?.blog?.AddBlog?.subHeading
                            }
                          />
                          <textarea
                            class="form-control heightText"
                            id="exampleFormControlTextarea1"
                            rows="5"
                          >
                            {appData?.Tabs?.blog?.AddBlog?.description}
                          </textarea>
                          <div className="upload_Btn">
                            <CustomButton
                              title="Upload blog"
                              onClick={handleOnBlog}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </HookForm>
                </div>
              </div>
            )}
            {!addBlog && (
              <div className="common_Header">
                <div className="search_Box">
                  <SearchBox
                    placeholder={appData?.Tabs?.blog?.searchPlaceHolder}
                  />
                </div>
                <div className="right_Section">
                  <div className="date_Inline">
                    <div className="date_Section">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText={
                          appData?.Tabs?.blog?.calendarStartPlaceHolder
                        }
                      />
                      <i className="icon-calendar iconCalendar"></i>
                    </div>
                    <div className="date_Section marginR">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText={
                          appData?.Tabs?.blog?.calendarEndPlaceHolder
                        }
                      />
                      <i className="icon-calendar iconCalendar"></i>
                    </div>
                  </div>
                  <CustomButton
                    title={appData?.Tabs?.blog?.addBtn}
                    onClick={handleOnBlog}
                  />
                </div>
              </div>
            )}
            {!addBlog && (
              <div className="card_Section">
                {appData?.Tabs?.blog?.blogData?.map((element) => {
                  return (
                    <div className="card_Main" key={element.id}>
                      <div className="img_Section">
                        <img
                          src={CardImg}
                          className="blog_Img displayDesktop"
                          alt="..."
                        />
                        <img
                          src={IpadImg}
                          className="blog_Img displayIpad"
                          alt="..."
                        />
                        <Dropdown className="custom_Dropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <i className="icon-edit iconCss"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Edit blog
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2" className="redC">
                              Delete blog
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="date_Section">
                          <span className="date">{element.date}</span>
                        </div>
                      </div>
                      <div className="content_Section">
                        <Label
                          className="main_Heading"
                          title={element.topic}
                        ></Label>
                        <Label
                          className="heading"
                          title={element.title}
                        ></Label>
                        <div className="inner_TxtSection">
                          <Label
                            className="inner_Txt"
                            title={element.desc}
                          ></Label>
                          <span className="read_More">{element.btnTitle}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Tab>
          <Tab eventKey="contact" title={appData.Tabs.contactForm.title}>
            <div className="common_Header">
              <div className="search_Box">
                <SearchBox
                  placeholder={appData.Tabs.contactForm.searchPlaceHolder}
                />
              </div>
              <div className="right_Section">
                <div className="date_Inline">
                  <div className="date_Section">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText={
                        appData.Tabs.contactForm.startPlaceHolder
                      }
                    />
                    <i className="icon-calendar iconCalendar"></i>
                  </div>
                  <div className="date_Section marginR">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText={appData.Tabs.contactForm.endPlaceHolder}
                    />
                    <i className="icon-calendar iconCalendar"></i>
                  </div>
                </div>
                <CustomButton
                  title={appData.Tabs.contactForm.downloadBtn}
                ></CustomButton>
              </div>
            </div>
            <div className="tab_Button">
              <CustomButton
                title={appData.Tabs.contactForm.contactFormBtn}
                onClick={() => handleTab("contactForm")}
              />
              <CustomButton
                title={appData.Tabs.contactForm.queryBtn}
                onClick={() => handleTab("query")}
              />
            </div>
            {tab === "contactForm" && (
              <div className="contact_Table">
                <ul className="header">
                  <li className="combine">
                    <ul className="combine_Li">
                      <li className="name">
                        {" "}
                        {appData.Tabs.contactForm.contactFormTable.fullName}
                      </li>
                      <li className="email">
                        {appData.Tabs.contactForm.contactFormTable.email}
                      </li>
                      <li className="phone">
                        {appData.Tabs.contactForm.contactFormTable.phone}
                      </li>
                    </ul>
                  </li>
                  <li className="date">
                    {appData.Tabs.contactForm.contactFormTable.date}
                  </li>
                  <li className="service">
                    {appData.Tabs.contactForm.contactFormTable.service}
                  </li>
                  <li className="project">
                    {appData.Tabs.contactForm.contactFormTable.projectBrief}
                  </li>
                </ul>
                <div>
                  {appData.Tabs.contactForm.contactFormTable.tableData.map(
                    (element) => {
                      return (
                        <ul className="data_List" key={element.id}>
                          <li className="combine">
                            <ul className="combine_Li">
                              <li className="name">{element.name}</li>
                              <li className="email">{element.email}</li>
                              <li className="phone">{element.phone}</li>
                            </ul>
                          </li>
                          <li className="date">{element.date}</li>
                          <li className="service">{element.service}</li>
                          <li className="project">{element.projectBrief}</li>
                        </ul>
                      );
                    }
                  )}
                </div>
              </div>
            )}

            {tab === "query" && (
              <div className="query">
                {/* {crtficte.map((tableData) => {
                  return ( */}
                <div className="query_card d-flex">
                  {appData.Tabs.contactForm.queryData.map((element) => {
                    return (
                      <div className="card_Section" key={element.id}>
                        <div className="content_section">
                          <div className="d-flex align-items-center">
                            <span className="">{element.phoneKey}</span>
                            <Label
                              title={element.phone}
                              className="pb-0 paddingLedt"
                            ></Label>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="">{element.emailKey}</span>
                            <Label
                              title={element.email}
                              className="pb-0 paddingLedt"
                            ></Label>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="">{element.phoneKey}</span>
                            <Label
                              title={element.phone}
                              className="pb-0 paddingLedt"
                            ></Label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="management" title="Management">
            <div className="contact_Table management_Table">
              <ul className="header">
                <li className="">Emp.ID</li>
                <li className="">Emp.Name</li>
                <li className="">Start date</li>
                <li className="">End date</li>
                <li className="">Leave type</li>
                <li className="">Reason</li>
                <li className="">Status</li>
              </ul>
              <ul className="data_List">
                <li className="">EMP1001</li>
                <li className="">Nancy Wrecko</li>
                <li className="">8/30/14</li>
                <li className="">8/30/14</li>
                <li className="leave">
                  <CustomDropdown
                    dropDownItems={LeaveType}
                    placeholder="Paid"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </li>
                <li className="service">
                  Amet minim mollit non deserunt ullam
                </li>
                <li className="status">
                  <CustomDropdown
                    dropDownItems={statusType}
                    placeholder="Approval"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </li>
              </ul>
              <ul className="data_List">
                <li className="">EMP1001</li>
                <li className="">Jeannette Schwartz</li>
                <li className="">8/30/14</li>
                <li className="">8/30/14</li>
                <li className="leave">
                  <CustomDropdown
                    dropDownItems={LeaveType}
                    placeholder="Paid"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </li>
                <li className="service">
                  Amet minim mollit non deserunt ullam
                </li>
                <li className="status">
                  <CustomDropdown
                    dropDownItems={statusType}
                    placeholder="Approval"
                    selectedValue={staff}
                    onSelect={(selected) => setSelectedStaff(selected)}
                  ></CustomDropdown>
                </li>
              </ul>
            </div>
            <div>
              <div className="leave_Main">
                <div className="leave_Type">
                  <LeaveCard
                    className="card_Width"
                    leaveNos="06"
                    leaveType="Applied leave"
                  ></LeaveCard>
                  <LeaveCard
                    className="card_Width"
                    leaveNos="16"
                    leaveType="Opening"
                  ></LeaveCard>
                  <LeaveCard
                    className="card_Width"
                    leaveNos="16"
                    leaveType="Generated"
                  ></LeaveCard>
                  <LeaveCard
                    className="card_Width"
                    leaveNos="03"
                    leaveType="Utilized"
                  ></LeaveCard>
                  <LeaveCard
                    className="card_Width"
                    leaveNos="03"
                    leaveType="Clossing balance"
                  ></LeaveCard>
                  <LeaveCard
                    className="card_Width"
                    leaveNos="02"
                    leaveType="Leave without pay"
                  ></LeaveCard>
                </div>
                <div className="user_Details">
                  <div className="name_Section">
                    <div className="common_Details">
                      <span className="common">Name</span>
                      <span className="bold">John doe</span>
                    </div>
                    <div className="common_Details">
                      <span className="common">Employee I’d </span>
                      <span className="bold">102930449</span>
                    </div>
                  </div>
                  <div className="button_Bottom">
                    <CustomButton
                      title="Apply Leave"
                      onClick={onClickApplyLeave}
                    ></CustomButton>
                  </div>
                </div>
                {!applyLeave ? (
                  <div className="leave_Table">
                    <ul className="header">
                      <li className="date">Start date</li>
                      <li className="date">End date</li>
                      <li className="leave">Leave type</li>
                      <li className="reason">Reason</li>
                      <li className="status">Status</li>
                    </ul>
                    {tableData.map((tableData) => {
                      return (
                        <ul className="data_List">
                          <li className="date">{tableData.startdate}</li>
                          <li className="date">{tableData.enddate}</li>
                          <li className="leave">{tableData.leavetype}</li>
                          <li className="reason">{tableData.reason}</li>
                          <li className="status">{tableData.status}</li>
                        </ul>
                      );
                    })}
                  </div>
                ) : (
                  <div className="apply_Leave_Section">
                    <Label title="Apply Leave"></Label>
                    <div className="date_Section">
                      <div className="date">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="Select start date"
                        />
                        <i className="icon-calendar iconCalendar"></i>
                      </div>
                      <div className="date">
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          placeholderText="Select end date"
                        />
                        <i className="icon-calendar iconCalendar"></i>
                      </div>
                    </div>
                    <textarea
                      class="form-control heightText"
                      id="exampleFormControlTextarea1"
                      placeholder="Write a reason for leave (Optional)"
                    ></textarea>
                    <div className="attached_Upload">
                      <form class="form">
                        <div
                          class="file-upload-wrapper"
                          data-text="Upload attachment"
                        >
                          <i className="icon-attached addIcon" />
                          <input
                            name="file-upload-field"
                            type="file"
                            class="file-upload-field"
                            value=""
                          />
                        </div>
                      </form>
                    </div>
                    <div className="leave_Bottom">
                      <CustomButton title="Cancel"></CustomButton>
                      <CustomButton title="Apply"></CustomButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="policy" title={appData.Tabs.policy.title}>
            <div className="privacy_page">
              <div className="policy_Button">
                <CustomButton
                  title={appData.Tabs.policy.editBtn}
                ></CustomButton>
                <CustomButton
                  title={appData.Tabs.policy.addBtn}
                  onClick={() => setHidemodal(!hidemodal)}
                ></CustomButton>
              </div>
              <div className="policy_Img">
                <img
                  src={PolicyImg}
                  className="w-100 position-relative"
                  alt="..."
                />
                <div className="img_Txt">
                  <Label title={appData.Tabs.policy.policyName}></Label>
                </div>
              </div>
              <div className="main_section">
                <div className="top_content">
                  <div className="top_heading">
                    <Label title="Privacy Policy" className="heading"></Label>
                    <div className="inner_text">
                      <Label
                        title={appData.Tabs.policy.data[0].textDesc}
                        className="text1"
                      ></Label>
                      <Label
                        title={appData.Tabs.policy.data[1].textDesc}
                        className="text1"
                      ></Label>
                      <Label
                        title={appData.Tabs.policy.data[2].textDesc}
                        className="text1"
                      ></Label>
                    </div>
                  </div>
                </div>
                <div className="middle_content">
                  <div className="middle_heading">
                    <Label
                      className="heading"
                      title={appData.Tabs.policy.subTitle}
                    ></Label>
                    <Label
                      className="text"
                      title={appData.Tabs.policy.subDesc}
                    ></Label>
                    <div className="inner_text">
                      <Label
                        title={appData.Tabs.policy.descriptionList[0].textDesc}
                        className="text1"
                      ></Label>
                      <Label
                        title={appData.Tabs.policy.descriptionList[1].textDesc}
                        className="text1"
                      ></Label>
                      <Label
                        title={appData.Tabs.policy.descriptionList[2].textDesc}
                        className="text1"
                      ></Label>
                      <Label
                        title={appData.Tabs.policy.descriptionList[3].textDesc}
                        className="text1"
                      ></Label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="holidaylist" title="Holiday List">
            <div className="right_Section holidayList">
              <CustomButton
                title={appData.Tabs.holidayList.addBtn}
                onClick={() => setHideHolidaymodal(!hideholidaymodal)}
              ></CustomButton>
            </div>
            <div className="contact_Table holiday_Table">
              <ul className="header">
                <li className="combine">
                  {appData.Tabs.holidayList.tableHead.date}
                </li>
                <li className="date">
                  {appData.Tabs.holidayList.tableHead.day}
                </li>
                <li className="service">
                  {appData.Tabs.holidayList.tableHead.holidayType}
                </li>
                <li className="project">
                  {appData.Tabs.holidayList.tableHead.action}
                </li>
              </ul>
              {appData.Tabs.holidayList.tableData.map((element) => {
                return (
                  <ul className="data_List" key={element.id}>
                    <li className="combine">{element.date}</li>
                    <li className="date">{element.day}</li>
                    <li className="service">{element.holidayType}</li>
                    <li className="project">
                      <span className="icon-pencil paddRight"></span>
                      <span className="icon-delete"></span>
                    </li>
                  </ul>
                );
              })}
            </div>
          </Tab>
          <Tab eventKey="faq" title={appData.Tabs.faq.title}>
            <div className="faq_Main">
              <CustomButton title={appData.Tabs.faq.addBtn}></CustomButton>
              <div className="accordion_Main">
                {/* Edit FAQ */}
                <div className="upload_Section ">
                  <div className="content_Section add_FAQ">
                    <HookForm
                      defaultForm={{}}
                      init={(form) => setLoginForm(form)}
                      onSubmit={onFormSubmit}
                    >
                      {(formMethod) => {
                        return (
                          <div className="form1">
                            <TextField
                              formMethod={formMethod}
                              name={loginForm.title.name}
                              autoFocus={true}
                              type="text"
                              placeholder={appData.Tabs.faq.faqTitlePlaceholder}
                            />
                            <textarea
                              class="form-control heightText"
                              id="exampleFormControlTextarea1"
                              rows="5"
                            >
                              {appData.Tabs.faq.faqAnswer}
                            </textarea>
                          </div>
                        );
                      }}
                    </HookForm>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="faqlist" title={appData.Tabs.faqListing.title}>
            <div className="faq_List">
              <div className="update__faq">
                <CustomButton
                  title={appData.Tabs.faqListing.updateBtn}
                ></CustomButton>
              </div>
              <div className="accordion__Block">
                <div className="accordion__faq">
                  {appData.Tabs.faqListing.faqData.map((item, index) => (
                    <div key={item.id} onClick={() => toggleAccordion(item.id)}>
                      <div className="accordion__Faq-heading">
                        <div className="accordion__Icon">
                          {accordion === item.id ? (
                            <>
                              <span className="vertical">
                                {" "}
                                <i className="icon-calendar iconCalendar" />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="vertical">
                                {" "}
                                <i className="icon-calendar iconCalendar" />
                              </span>
                            </>
                          )}
                        </div>
                        <div className={accordion === item.id ? "active" : ""}>
                          {item.question}
                        </div>
                      </div>

                      <div className="accordion__Answer">
                        <div
                          className={
                            accordion === item.id ? "active" : "inactive"
                          }
                        >
                          {item.answere}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="accordion__Dots">
                  <Dropdownx />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
      <ModalPopup
        showModal={hidemodal}
        onHide={() => setHidemodal(false)}
        onHideClick={() => setHidemodal(false)}
        headerTitle={appData.Tabs.policy.addPolicyModal.addPolicy}
        buttonTitle={appData.Tabs.policy.addPolicyModal.addBtn}
      >
        <div className="inner_Modal">
          <textarea
            class="form-control heightText"
            id="exampleFormControlTextarea1"
            rows="5"
          >
            {appData.Tabs.policy.addPolicyModal.descriptionPlaceholder}
          </textarea>
        </div>
      </ModalPopup>
      <ModalPopup
        showModal={hideholidaymodal}
        onHide={() => setHideHolidaymodal(false)}
        onHideClick={() => setHideHolidaymodal(false)}
        headerTitle={appData.Tabs.holidayList.addHolidayModal.addPolicy}
        buttonTitle={appData.Tabs.holidayList.addHolidayModal.addBtn}
      >
        <div className="holiday_Modal">
          <div className="date_Section">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText={
                appData.Tabs.holidayList.addHolidayModal.selectDatePlaceholder
              }
            />
            <i className="icon-calendar iconCalendar"></i>
          </div>
          <CustomDropdown
            dropDownItems={dropdownItem}
            placeholder="Select Holiday_Type"
            selectedValue={staff}
            onSelect={(selected) => setSelectedStaff(selected)}
          ></CustomDropdown>
        </div>
      </ModalPopup>
      <Footer />
    </div>
  ) : (
    React.createElement(
      "div",
      { className: "ElseBlock" },
      React.createElement("h1", {}, "...loading")
    )
  );
}

export default DetailsPage;
