import {useState,useEffect} from 'react'
import Header from '../../components/Header/Header'
import Label from '../../components/Label/Label'
import Footer from '../../components/Footer/Footer'
import './TermCondition.scss'
import { useDispatch, useSelector } from "react-redux";
import { getTermConditionData } from "../../reduxThings/slices/wbildSlices";
function TermCondition() {
    const [carousalStatus, setCarousalStatus] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTermConditionData());
      }, []);
    const appData = useSelector(
        (e) => e?.wbildSlice.termConditionState.termConditionStateData
      );
    const handleClick = (data) => {
      setCarousalStatus(data);
    };
    return typeof appData.title === "string" ? (
        <div className="Term_Condition_main_container">
          <div className="Term_Condition_Page">
            <Header handleClick={handleClick} />
            <div className="main_section">
              <div className="top_content">
                <div className="top_heading">
                  <Label title={appData.title} className="heading"></Label>
                  <div className="inner_text">
                    <Label
                      title={appData.data[0].textDesc}
                      className="text1"
                    ></Label>
                    <Label
                     title={appData?.data[1].textDesc} className="text1"
                    ></Label>
                    <Label
                     title={appData?.data[2].textDesc} className="text1"
                    ></Label>
                  </div>
                </div>
              </div>
              <div className="middle_content">
                <div className="middle_heading">
                  <Label
                    className="heading"
                    title={appData.subTitle}
                  ></Label>
                  <Label
                    className="text"
                    title={appData.subDesc} ></Label>
                  <div className="list">
                    <ul>
                      <li>
                      {appData.list[0].textDesc}
                      </li>
                      <li>
                      {appData.list[1].textDesc}
                      </li>
                      <li>
                      {appData.list[2].textDesc}
                      </li>
                    </ul>
                  </div>
                  <div className="inner_text">
                    <Label
                      title={appData.descriptionList[0].textDesc}
            
                      className="text1"
                    ></Label>
                    <Label
                      title={appData.descriptionList[1].textDesc}
                      className="text1"
                    ></Label>
                    <Label
                      title={appData.descriptionList[2].textDesc}
                    
                      className="text1"
                    ></Label>
                    <Label
                      title={appData.descriptionList[3].textDesc}
                      className="text1"
                    ></Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      ) : (
        <div>...loading</div>
      );
    }

export default TermCondition
