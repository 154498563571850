import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import {
  NavDropdown,
  Nav,
  Navbar,
  Accordion,
  Container,
  Dropdown,
} from "react-bootstrap";
import "./Header.scss";
import LogoImg from "../../assests/img/logo.png";
import BottomImg from "../../assests/img/menu-bg.png";
import CustomButton from "../CustomButton/CustomButton";
import Label from "../Label/Label";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import socialImg from "../../assests/img/social.svg";

const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  let navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const clickToNavigate = (page) => {
    if (page === "bloglisting1111") {
      navigate("/bloglisting");
    } else if (page === "contactUs1212") {
      navigate("/contactpage");
    } else if (page === "iosDevelopment") {
      navigate("/iosdevelopment");
    } else if (page === "homepage") {
      navigate("/");
    }
  };
  const ModalPort = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose} className="modal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modalBody">
            <div className="3DExteriorDesign_Page">
              <div className="content_Section">
                <Label title="3DExteriorDesign"></Label>
                <div></div>
                <Label
                  title="Sed ut perspiciatis unde omnis 
              iste natus error sit voluptatem accusantium doloremque
               laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                ></Label>
              </div>

              <Carousel className="image_Section">
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  console.log("pathname", location.pathname);
  return (
    <Navbar bg="light" expand="lg" className="ml-auto menuListing">
      <ModalPort />
      <Container className="container_Section">
        <Navbar.Brand>
          <div
            class="brand"
            className="logo-Img"
            onClick={() => clickToNavigate("homepage")}
          >
            <img src={LogoImg} alt="..." />
          </div>
        </Navbar.Brand>
        <div className="main-Container">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            
            <Nav className="me-auto">
            <Nav.Link onClick={() => clickToNavigate("floorPlans")}>
                Floor Plans
              </Nav.Link>
              <Nav.Link onClick={() => clickToNavigate("3DExteriorDesign")}>
                3D Exterior Design
              </Nav.Link>

              <Nav.Link onClick={() => clickToNavigate("bloglisting")}>
                Interior Design
              </Nav.Link>
              <Nav.Link onClick={() => clickToNavigate("contactUs")}>
                Blogs
              </Nav.Link>
              <Nav.Link onClick={() => clickToNavigate("contactUs")}>
                Immersive VR Experience
              </Nav.Link>

              <Nav.Link
                onClick={() => clickToNavigate("contactUs")}
                className="colorRed"
              >
                Get Quote
              </Nav.Link>
              {/* <Nav.Link>
                <span className="profile">
                  <i className="icon-profile profileCss"></i>
                </span>
              </Nav.Link> */}
              <Dropdown className="profile-dropdown">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <span className="profile">
                    <i className="icon-profile profileCss"></i>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-profile profileCss dropdown-profile"></i>{" "}
                      Animesh
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-mail profileCss dropdown-profile"></i>{" "}
                      animesh@wbild.com
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-setting profileCss dropdown-profile"></i>{" "}
                      Logout
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
