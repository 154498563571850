import { call, takeLatest, all, put } from "@redux-saga/core/effects";
import {
  getCatAction,
  successCatAction,
  successHomepageAction,
  getHomepageAction,
  successFooterDataAction,
  getFooterDataAction,
  successOurServiceDataAction,
  getOurServiceDataAction,
  getIosDataAction,
  successIosDataAction,
  successContactUsData,
  getContactUsData,
  getPrivacyPolicyData,
  successPrivacyPolicyData,
  getTermConditionData,
  successTermConditionData,
  getPackagesData,
  successPackagesData,
  getBlogListAction,
  successBlogListAction,
  getBlogPageAction,
  successblogPageStateAction,
  getVerifyCertificateAction,
  successVerifyCertificate,
  getCaseStudyAction,
  successCaseStudyAction,
  getPortfolioAction,
  successPortfolioAction,
  getAboutUsAction,
  successAboutUsAction,
  getDetailsPageAction,
  successDetailsPageAction,
  successLoginPageAction,
  getLoginPageAction,successCareerPageAction,
  getCareerPageAction
} from "../reduxThings/slices/wbildSlices";
import * as Api from "../services/Api";

// import HomepageData from "../../src/emptyData/HomepageData.json";
// import footer from "../../src/emptyData/footer.json";
// import ourService from "../../src/emptyData/OurServices(Content3).json";
// import ios from "../emptyData/IosDevelopment(Content4).json";
// import contactUs from "../emptyData/ContactUs(Content5).json";
// import privacyPolicy from "../emptyData/PrivacyPolicy(Content6).json";
// import termCondition from "../emptyData/TermAndCondition(Content7).json";
// import packages from "../emptyData/Packages(Content8).json";
// import BlogList from "../emptyData/BlogList(Content10).json";
// import blogPage from "../emptyData/Blog(Content11).json";
// import verifyCertificate from "../emptyData/VerifyCertificate(Content12,13,14,15,16).json";
// import caseStudy from "../emptyData/CaseStudy(Content17).json";
// import portfolio from "../emptyData/Portfolio(Content18).json";
// import aboutUs from "../emptyData/About(Content19).json"
// import detailsPage from "../emptyData/DetailsPage(Content23,24,25,26,27,28).json";
// import login from "../emptyData/Login(Content22).json"
// import career from "../emptyData/CareerPage(Content31).json";

import HomepageData from "../Data/HomepageData.json";
import footer from "../Data/footer.json";
import ourService from "../Data/OurServices(Content3).json";
import ios from "../Data/IosDevelopment(Content4).json";
import contactUs from "../Data/ContactUs(Content5).json";
import privacyPolicy from "../Data/PrivacyPolicy(Content6).json";
import termCondition from "../Data/TermAndCondition(Content7).json";
import packages from "../Data/Packages(Content8).json";
import BlogList from "../Data/BlogList(Content10).json";
import blogPage from "../Data/Blog(Content11).json";
import verifyCertificate from "../Data/VerifyCertificate(Content12,13,14,15,16).json";
import caseStudy from "../Data/CaseStudy(Content17).json";
import portfolio from "../Data/Portfolio(Content18).json";
import aboutUs from "../Data/About(Content19).json"
import detailsPage from "../Data/DetailsPage(Content23,24,25,26,27,28).json"
import login from "../Data/Login(Content22).json"
import career from "../Data/CareerPage(Content31).json";


function* fetchCatData(action) {
  try {
    let payloadData = action.payloadData;
    const response = yield call(Api.fetchCatApiResult, payloadData);
    console.trace("cat Api called from");
    yield put(successCatAction(response));
  } catch (error) {
    console.log(error);
  }
}

function* fetchHomeData(action) {
  try {
    // let payloadData=action.payloadData;
    console.log("calle saga success");
    yield put(successHomepageAction(HomepageData));
  } catch (error) {
    console.log(error);
  }
}
function* fetchFooterDta(action) {
  try {
    // let payloadData=action.payloadData;
    yield put(successFooterDataAction(footer));
  } catch (error) {
    console.log(error);
  }
}
function* fetchOurServiceData() {
  try {
    yield put(successOurServiceDataAction(ourService));
  } catch (error) {
    console.log(error);
  }
}
function* fetchIosData() {
  try {
    yield put(successIosDataAction(ios));
  } catch (error) {
    console.log(error);
  }
}
function* fetchContactUsData() {
  try {
    yield put(successContactUsData(contactUs));
  } catch (error) {
    console.log(error);
  }
}
function* fetchPrivacyPolicyData() {
  try {
    yield put(successPrivacyPolicyData(privacyPolicy));
  } catch (error) {
    console.log(error);
  }
}
function* fetchTermConditionData() {
  try {
    yield put(successTermConditionData(termCondition));
  } catch (error) {
    console.log(error);
  }
}
function* fetchPackagesData() {
  try {
    yield put(successPackagesData(packages));
  } catch (error) {}
}
function* fetchBlogList() {
  try {
    // let payloadData = action.payload;
    // const response = yield call(Api.fetchCatApiResults, payloadData);
    // console.log(response ,"response")
    yield put(successBlogListAction(BlogList));

    console.log("buddha journey saga bloglist action  called");
    // const user = yield call(Api.fetchUser, action.payload.userId)
    // yield put({ type: 'USER_FETCH_SUCCEEDED', user: user })
  } catch (e) {
    console.log("catch");
  }
}
function* fetchBlogPageAction() {
  try {
    yield put(successblogPageStateAction(blogPage));
  } catch (error) {}
}
function* fetchVerifyCertificateData() {
  try {
    yield put(successVerifyCertificate(verifyCertificate));
  } catch (error) {}
}
function* fetchCaseStudyData() {
  try {
    yield put(successCaseStudyAction(caseStudy));
  } catch (error) {
    console.log(error);
  }
}
function* fetchPortfolioData() {
  try {
    yield put(successPortfolioAction(portfolio));
  } catch (error) {
    console.log(error);
  }
}
function* fetchAboutUsData() {
  try {
    yield put(successAboutUsAction(aboutUs));
  } catch (error) {
    console.log(error);
  }
}
function* fetchDetailsPageData(){
  try{
yield put (successDetailsPageAction(detailsPage))
  }
  catch(error){
    console.log(error)
  }
}
function* fetchLoginData(){
  try{
yield put(successLoginPageAction(login))
  }
  catch(err){
    console.log(err)
  }
}
function* fetchCareerData(){
  try{
yield put(successCareerPageAction(career))
  }
  catch(err){
    console.log(err)
  }
}
function* wbildSaga() {
  yield all([
    takeLatest(getCatAction.type, fetchCatData),
    takeLatest(getHomepageAction.type, fetchHomeData),
    takeLatest(getFooterDataAction.type, fetchFooterDta),
    takeLatest(getOurServiceDataAction.type, fetchOurServiceData),
    takeLatest(getIosDataAction.type, fetchIosData),
    takeLatest(getContactUsData.type, fetchContactUsData),
    takeLatest(getPrivacyPolicyData.type, fetchPrivacyPolicyData),
    takeLatest(getTermConditionData.type, fetchTermConditionData),
    takeLatest(getPackagesData.type, fetchPackagesData),
    takeLatest(getBlogListAction.type, fetchBlogList),
    takeLatest(getBlogPageAction.type, fetchBlogPageAction),
    takeLatest(getVerifyCertificateAction.type, fetchVerifyCertificateData),
    takeLatest(getCaseStudyAction.type, fetchCaseStudyData),
    takeLatest(getPortfolioAction.type, fetchPortfolioData),
    takeLatest(getAboutUsAction.type,fetchAboutUsData),
    takeLatest(getDetailsPageAction.type,fetchDetailsPageData),
    takeLatest(getLoginPageAction.type,fetchLoginData),
    takeLatest(getCareerPageAction.type,fetchCareerData)
  ]);
}
export default wbildSaga;
