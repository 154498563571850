import React, { useEffect, useState } from "react";
// import axios from "axios";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import Message from "../../util/message";
import Constant from "../../util/constant";
import LoginImg from "../../assests/img/loginimg.svg";
import Logo from "../../assests/img/logo.svg";
import CheckBox from "../../components/CheckBox/CheckBox";
import Label from "../../components/Label/Label";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getLoginPageAction } from "../../reduxThings/slices/wbildSlices";

const loginForm = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};

function Login() {
  let navigate = useNavigate();
  const dispatch=useDispatch();
  const clickToNavigateDetailsPage = () => {
    navigate("/detailspage");
  };
  const clickToNavigateLeavePage = () => {
    navigate("/leave");
  };
  const [form, setLoginForm] = React.useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const onFormSubmit = (e) => {
    console.log(e);

    //    console.log("clicked")
    //    axios.get(api , { headers: {"Authorization" : `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1NzQwYTI2Ny02ZjIxLTQyOGMtOTViYy0wM2EwY2ExODdmOWUiLCJyb2wiOlsiVVNFUiJdLCJpYXQiOjE2NTE5OTQ0MTQsImV4cCI6MTY1MjU5OTIxNH0.eRGYbfxKxaoNZ_OMuByh6FsNGkfekidkqtgyKTZ-50A'}`} })
    // .then((res) => {
    //     console.log(res.data)
    //   const persons = res.data;
    //   setState( persons );
    //   console.log(persons);

    // })
  };
useEffect(()=>{
dispatch(getLoginPageAction());
},[])
const appData=useSelector(e=>e?.wbildSlice?.LoginPageState?.LoginPageStateData)
console.log(appData)
  return (
    <div className="login_Container">
      <Header handleClick={handleClick} />
      <div
        className="login_Page"
        style={{ height: dimensions.height - 120 + "px" }}
      >
        <div className="center_Section">
          <div className="left_Side">
            <div className="img_Section">
              <img
                src={LoginImg}
                alt="..."
                fluid="true"
                className="main_Img"
              />
              <img src={Logo} alt="..." fluid="true" className="main_Img" />
            </div>
          </div>
          <div className="right_Side">
            <div className="input_Center">
              <Label title={appData.loginBtn} className="titleText"></Label>
              <HookForm
                defaultForm={{}}
                init={(form) => setLoginForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="form1">
                      <TextField
                        formMethod={formMethod}
                        rules={loginForm.email.validate}
                        name={loginForm.email.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder={appData.emailPlaceholder}
                      />
                      <TextField
                        formMethod={formMethod}
                        rules={loginForm.password.validate}
                        name={loginForm.password.name}
                        errors={formMethod?.errors}
                        placeholder={appData.password}
                        iconClass="Password"
                        textFieldCss="textFieldCss"
                      />
                      <div className="remember_Forgot">
                        <CheckBox labelTitle={appData.checkbox} id="test2" />
                        <a href="" className="forgot_Pass">
                        {appData.forgotPassword}
                        </a>
                      </div>
                      <div className="login_Btn">
                        <CustomButton
                          title={appData.loginBtn}
                          onClick={(onFormSubmit, clickToNavigateDetailsPage)}
                        ></CustomButton>
                      </div>
                    </div>
                  );
                }}
              </HookForm>
              {/* <CustomButton
                title="Leave Page"
                onClick={clickToNavigateLeavePage}
              ></CustomButton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
