import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Label from "../../components/Label/Label";
import BannerLeft from "../../assests/img/banners.svg";
import BannerRight from "../../assests/img/bannershadow.svg";
import MobileImg from "../../assests/img/mobileapp.svg";
import WebImg from "../../assests/img/webimg.svg";
import UIImg from "../../assests/img/uiimg.svg";
import "./Ourservices.scss";
import ContactUs from "../../components/ContactUs/ContactUs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOurServiceDataAction } from "../../reduxThings/slices/wbildSlices";
function ServicesPage() {
  let navigate = useNavigate();
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();

  const handleClick = (data) => {
    setCarousalStatus(data);
  };

  const clickToNavigate = () => {
    navigate("/portfoliolisting");
  };
  useEffect(() => {
    dispatch(getOurServiceDataAction());
    window.scrollTo(0, 0);
  }, []);
  const appData = useSelector(
    (e) => e?.wbildSlice?.ourServiceState?.ourServiceStateData
  );
  return typeof appData.title === "string" ? (
    <div className="App">
      <Header handleClick={handleClick} />
      <div className="service_Page">
        <div className="banner_Text_Img">
          <div className="inline_Img">
            <img src={BannerLeft} className="img_Left" alt="..." />
            <div className="banner_Text">
              <Label title={appData.title} className="main_Heading"></Label>
              <Label title={appData.subTitle}></Label>
            </div>
          </div>
          <img src={BannerRight} className="img_Right" alt="..." />
        </div>
        <div className="content_Img">
          <div className="content width50">
            <Label
              title={appData?.cardData[0]?.heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData?.cardData[0]?.paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData?.cardData[0]?.points[0].point}></Label>
                <Label title={appData?.cardData[0]?.points[1].point}></Label>
                <Label title={appData?.cardData[0]?.points[2].point}></Label>
                <Label title={appData?.cardData[0]?.points[3].point}></Label>
              </div>
              <div className="right small50">
                <Label title={appData?.cardData[0]?.points[4].point}></Label>
                <Label title={appData?.cardData[0]?.points[5].point}></Label>
                <Label title={appData?.cardData[0]?.points[6].point}></Label>
                <Label title={appData?.cardData[0]?.points[7].point}></Label>
              </div>
            </div>
          </div>
          <div className="image width50">
            <div className="img_Div">
              <img
                src={MobileImg}
                className="img_Left"
                onClick={clickToNavigate}
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="content_Img web_Section">
          <div className="image width50">
            <div className="img_Div">
              <img src={WebImg} className="img_Left" alt="..." />
            </div>
          </div>
          <div className="content width50">
            <Label
              title={appData?.cardData[1]?.heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData?.cardData[1]?.paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData?.cardData[1]?.points[0].point}></Label>
                <Label title={appData?.cardData[1]?.points[1].point}></Label>
                <Label title={appData?.cardData[1]?.points[2].point}></Label>
                <Label title={appData?.cardData[1]?.points[3].point}></Label>
              </div>
              <div className="right small50">
                <Label title={appData?.cardData[1]?.points[4].point}></Label>
                <Label title={appData?.cardData[1]?.points[5].point}></Label>
                <Label title={appData?.cardData[1]?.points[6].point}></Label>
                <Label title={appData?.cardData[1]?.points[7].point}></Label>
              </div>
            </div>
          </div>
        </div>
        <div className="content_Img ui_Section">
          <div className="content width50">
            <Label
              title={appData?.cardData[2]?.heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData?.cardData[2]?.paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData?.cardData[2]?.points[0].point}></Label>
                <Label title={appData?.cardData[2]?.points[1].point}></Label>
                <Label title={appData?.cardData[2]?.points[2].point}></Label>
                <Label title={appData?.cardData[2]?.points[3].point}></Label>
              </div>
              <div className="right small50">
                <Label title={appData?.cardData[2]?.points[4].point}></Label>
                <Label title={appData?.cardData[2]?.points[5].point}></Label>
                <Label title={appData?.cardData[2]?.points[6].point}></Label>
                <Label title={appData?.cardData[2]?.points[7].point}></Label>
              </div>
            </div>
          </div>
          <div className="image width50">
            <div className="img_Div">
              <img src={UIImg} className="img_Left" alt="..." />
            </div>
          </div>
        </div>
        <div className="content_Img marketing_Section">
          <div className="image width50">
            <div className="img_Div">
              <img src={MobileImg} className="img_Left" alt="..." />
            </div>
          </div>
          <div className="content width50">
            <Label
              title={appData?.cardData[3]?.heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData?.cardData[3]?.paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData?.cardData[3]?.points[0].point}></Label>
                <Label title={appData?.cardData[3]?.points[1].point}></Label>
                <Label title={appData?.cardData[3]?.points[2].point}></Label>
                <Label title={appData?.cardData[3]?.points[3].point}></Label>
              </div>
            </div>
          </div>
        </div>
        <ContactUs />
        <Footer></Footer>
      </div>
    </div>
  ) : (
    <div>...loading</div>
  );
}

export default ServicesPage;
