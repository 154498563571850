import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Label from "../../components/Label/Label";
import ClientImg from "../../assests/img/clients.png";
import CultureImg from "../../assests/img/culture.png";
import LeaderImg from "../../assests/img/leader.png";
import MeetImg from "../../assests/img/meet.png";
import MeetImg1 from "../../assests/img/meet1.png";
import MeetImg2 from "../../assests/img/meet2.png";
import ContactUs from "../../components/ContactUs/ContactUs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AboutUs.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUsAction } from "../../reduxThings/slices/wbildSlices";
function AboutUs() {
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    dispatch(getAboutUsAction());
  }, []);
  const appData = useSelector(
    (e) => e?.wbildSlice?.AboutUsState?.AboutUsStateData
  );
  return appData?.title === "About wbild" ? (
    <div className="App">
      <Header handleClick={handleClick} />
      <div className="about_Page">
        <div className="heading_Section">
          <Label title={appData?.title} className="about_us"></Label>
          <Label title={appData?.subTitle} className="about_Inner"></Label>
        </div>
        <div className="content_Img ui_Section padd_Bottom">
          <div className="content width50">
            <Label
              title={appData.cardData[0].heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData.cardData[0].paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData.cardData[0].points[0].point}></Label>
                <Label title={appData.cardData[0].points[1].point}></Label>
                <Label title={appData.cardData[0].points[2].point}></Label>
                <Label title={appData.cardData[0].points[3].point}></Label>
              </div>
              <div className="right small50">
                <Label title={appData.cardData[0].points[4].point}></Label>
                <Label title={appData.cardData[0].points[5].point}></Label>
                <Label title={appData.cardData[0].points[6].point}></Label>
                <Label title={appData.cardData[0].points[7].point}></Label>
              </div>
            </div>
          </div>
          <div className="image width50">
            <div className="img_Div">
              <img src={ClientImg} className="img_Left" alt="..."></img>
            </div>
          </div>
        </div>
        <div className="content_Img ui_Section padd_Bottom">
          <div className="image width50">
            <div className="img_Div">
              <img src={CultureImg} className="img_Left" alt="..."></img>
            </div>
          </div>
          <div className="content width50">
            <Label
              title={appData.cardData[1].heading}
              className="main_Title"
            ></Label>
            <Label
              title={appData.cardData[1].paragraph}
              className="inner_Title"
            ></Label>
            <div className="skill_Section">
              <div className="left small50">
                <Label title={appData.cardData[1].points[0].point}></Label>
                <Label title={appData.cardData[1].points[1].point}></Label>
                <Label title={appData.cardData[1].points[2].point}></Label>
                <Label title={appData.cardData[1].points[3].point}></Label>
              </div>
              <div className="right small50">
                <Label title={appData.cardData[1].points[4].point}></Label>
                <Label title={appData.cardData[1].points[5].point}></Label>
                <Label title={appData.cardData[1].points[6].point}></Label>
                <Label title={appData.cardData[1].points[7].point}></Label>
              </div>
            </div>
          </div>
        </div>
        <div className="content_Img ui_Section padd_Bottom leader_Section">
          <div className="content width20">
            <div className="skill_Section">
              <div className="left small50">
                <div className="header">
                  <Label
                    className="heading"
                    title={appData.ourLeadership.heading}
                  ></Label>
                  <Label
                    className="inner"
                    title={appData.ourLeadership.subHeading}
                  ></Label>
                </div>
                <div className="name_Designation">
                  <Label
                    className="name"
                    title={appData.ourLeadership.leadersData[0].name}
                  ></Label>
                  <Label
                    className="designation"
                    title={appData.ourLeadership.leadersData[0].role}
                  ></Label>
                </div>
                <div className="inner_Details">
                  <Label className="main_Text" title={appData.ourLeadership.leadersData[1].name}></Label>
                  <Label
                    className="text"
                    title={appData.ourLeadership.leadersData[1].name}
                  ></Label>
                </div>
                <div className="inner_Details">
                <Label className="main_Text" title={appData.ourLeadership.leadersData[2].name}></Label>
                  <Label
                    className="text"
                    title={appData.ourLeadership.leadersData[2].name}
                  ></Label>
                </div>
                <div className="inner_Details">
                <Label className="main_Text" title={appData.ourLeadership.leadersData[3].name}></Label>
                  <Label
                    className="text"
                    title={appData.ourLeadership.leadersData[3].name}
                  ></Label>
                </div>
                <div className="inner_Details">
                <Label className="main_Text" title={appData.ourLeadership.leadersData[4].name}></Label>
                  <Label
                    className="text"
                    title={appData.ourLeadership.leadersData[4].name}
                  ></Label>
                </div>
              </div>
            </div>
          </div>
          <div className="image width60">
            <div className="img_Div">
              <img src={LeaderImg} className="img_Left" alt="..."></img>
              <div className="img_Text">
                <div className="bg_Text">
                  <Label className="name" title={appData.ourLeadership.leadersData[0].name}></Label>
                  <Label className="designation" title={appData.ourLeadership.leadersData[0].role}></Label>
                  <Label
                    className="inner"
                    title={appData.ourLeadership.leadersData[0].intro}
                  ></Label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="img_Slider">
          <Slider {...settings}>
            <div className="imgSlider">
              <div className="imgSlider_Inner">
                <img src={MeetImg} className="img_Left" alt="..."></img>
                <Label className="head" title={appData.somewbildians[0].name}></Label>
                <Label
                  className="inner"
                  title={appData.somewbildians[0].intro}
                ></Label>
              </div>
            </div>

            <div className="imgSlider">
              <div className="imgSlider_Inner">
                <img src={MeetImg1} className="img_Left" alt="..."></img>
                <Label className="head" title={appData.somewbildians[1].name}></Label>
                <Label
                  className="inner"
                  title={appData.somewbildians[1].intro}
                ></Label>
              </div>
            </div>
            <div className="imgSlider">
              <div className="imgSlider_Inner">
                <img src={MeetImg2} className="img_Left" alt="..."></img>
                <Label className="head" title={appData.somewbildians[2].name}></Label>
                <Label
                  className="inner"
                  title={appData.somewbildians[2].intro}
                ></Label>
              </div>
            </div>
            <div className="imgSlider">
              <div className="imgSlider_Inner">
                <img src={MeetImg1} className="img_Left" alt="..."></img>
                <Label className="head" title={appData.somewbildians[3].name}></Label>
                <Label
                  className="inner"
                  title={appData.somewbildians[3].intro}
                ></Label>
              </div>
            </div>
          </Slider>
        </div>
        <ContactUs />
        <Footer></Footer>
      </div>
    </div>
  ) : (
    React.createElement("h1", {}, "...loading")
  );
}

export default AboutUs;
