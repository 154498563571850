import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dropdown from 'react-bootstrap/Dropdown'
import "./Dropdownx.scss"
function Dropdownx() {
  return <div className='dropdown_Div'>
      <Dropdown>
  <Dropdown.Toggle variant="success" id="dropdown-basic">
   
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">1BHK</Dropdown.Item>
    <Dropdown.Item href="#/action-2">2BHK</Dropdown.Item>
    <Dropdown.Item href="#/action-3">3BHK</Dropdown.Item>
    <Dropdown.Item href="#/action-4">4BHK</Dropdown.Item>
    <Dropdown.Item href="#/action-5">Studio Apartment</Dropdown.Item>
    <Dropdown.Item href="#/action-6">Penthouse</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
  </div>;
}
export default Dropdownx;