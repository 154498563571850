import React, { useState,useEffect } from "react";
import Header from "../../components/Header/Header";
import "./PackagesSEO.scss";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesData } from "../../reduxThings/slices/wbildSlices";

function PackagesSEO() {
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  const dispatch=useDispatch()
  useEffect(()=>{
dispatch(getPackagesData())
  },[])
  const appData=useSelector((e)=>e?.wbildSlice?.packagesState?.packagesStateData)
  return typeof appData?.title === 'string'?(
    <div className="package_Seo_Container">
      <Header handleClick={handleClick} />
      <Footer></Footer>
    </div>
  )
  :<div>...loading</div>
}

export default PackagesSEO;